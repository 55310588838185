import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { OutboundLink } from "gatsby-plugin-gtag"

const Footer = ({ siteTitle }) => (
  <StyledFooter>
    <div className="uk-container uk-padding uk-text-center">
      Copyright © {new Date().getFullYear()} BRIDGE. All rights reserved. {` `} 
      {/*<a href="/legal">Terms &amp; Conditions</a>*/}
      <a href="/privacy">Privacy Policy</a>
      <br />
      Site by <OutboundLink href="https://www.solidmark.com" target="_blank">Solidmark</OutboundLink>.
      {` `}
    </div>
  </StyledFooter>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

const StyledFooter = styled.footer`
  border-top: 1px solid rgba(0,0,0,0.3);
`

export default Footer
